import React from "react"
import { InlineWidget } from "react-calendly";

import SEO from "../components/seo"

const ScheduleACallPage = () => (
  <>
    <SEO title="Schedule a call" />
    <div className="container">
      <InlineWidget url="https://calendly.com/hades-media" styles={{
        height: '100vh'
      }} />
    </div>
  </>
)

export default ScheduleACallPage
